import React, { useEffect, useRef, useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { IPage, usePageContext } from '@alterpage/gatsby-plugin-alterpress-page-creator';

import {
    layout,
    container,
    box,
    top,
    bottom,
    semicircleTop,
    code,
    codeText,
    text,
    section,
    buttons,
} from './error-page.module.scss';
import { breakpoints } from '../config/breakpoints';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';
import { TAccentColor } from '../models/accent-color.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IOfferCategory } from '../models/offer.model';

import MainLayout from '../layouts/main-layout';
import Button from '../components/atoms/button';
import Semicircle from '../components/atoms/semicircle';
import Section from '../components/hoc/section';
import Markdown from '../components/hoc/markdown';

interface IStartMouseX {
    value: undefined | number;
}

interface IErrorPageStaticQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
    allOfferCategory: IQueryAllResult<IOfferCategory>;
}

const ErrorPage: React.FC = () => {
    const { t } = useT();
    const { locale } = usePageContext();
    const { allPage, allOfferCategory } = useStaticQuery<IErrorPageStaticQueryResult>(query);

    const pages = getNodes(allPage);
    const offerCategories = getNodes(allOfferCategory);
    const localizedPages = pages.filter((page) => page.locale === locale);
    const localOfferCategories = offerCategories.filter((category) => category.locale === locale);

    const portfolioPathname = localizedPages.find((page) => page.type === 'portfolio')?.pathname;
    const aboutPathname = localizedPages.find((page) => page.type === 'about')?.pathname;
    const categoryPathname = localOfferCategories[0]?.pathname;

    const boxTop = useRef<HTMLDivElement>(null);
    const boxBottom = useRef<HTMLDivElement>(null);
    const errorCode = useRef<HTMLSpanElement>(null);
    const startMouseX = useRef<IStartMouseX>({
        value: undefined,
    });

    const [semicircleTopColor, setSemicircleTopColor] = useState<TAccentColor>('pink');
    const [semicircleBottomColor, setSemicircleBottomColor] = useState<TAccentColor>('purple');

    useEffect(() => {
        if (!boxTop.current || !boxBottom.current || !errorCode.current) {
            return;
        }

        const errorCodeNode = errorCode.current;
        const boxes = [boxTop.current, boxBottom.current];

        if (window.innerWidth > breakpoints.tablet) {
            boxes.forEach((box) => (box.style.transform = 'translateX(12%)'));
            errorCodeNode.style.transform = 'translateX(0%)';

            document.addEventListener('mousemove', (event) => {
                if (startMouseX.current.value === undefined) {
                    startMouseX.current.value = event.clientX;
                    return;
                }

                const newMouseX = event.clientX;
                const mouseDeltaX = newMouseX - startMouseX.current.value;
                startMouseX.current.value = newMouseX;

                boxes.forEach((box) => {
                    let translateX = getActualTranslateX(box);

                    if (box.classList.contains(top)) {
                        box.style.transform = `translateX(${translateX + mouseDeltaX / 45}%)`;
                        translateX = getActualTranslateX(box);

                        if (translateX < -10) {
                            setSemicircleTopColor('yellow');
                            setSemicircleBottomColor('blue');
                        } else if (translateX >= -10 && translateX < 10) {
                            setSemicircleTopColor('pink');
                            setSemicircleBottomColor('purple');
                        } else if (translateX >= 10 && translateX < 30) {
                            setSemicircleTopColor('blue');
                            setSemicircleBottomColor('yellow');
                        } else if (translateX >= 30) {
                            setSemicircleTopColor('purple');
                            setSemicircleBottomColor('pink');
                        }
                    }

                    if (box.classList.contains(bottom)) {
                        box.style.transform = `translateX(${translateX - mouseDeltaX / 60}%)`;
                    }
                });

                errorCodeNode.style.transform = `translate(${
                    getActualTranslateX(errorCodeNode) - mouseDeltaX / 180
                }%)`;
            });
        }
    }, [boxTop, boxBottom]);

    return (
        <MainLayout className={layout}>
            <Section className={section} theme="dark" circleDisplay="none">
                <div className={container}>
                    <div className={`${box} ${top}`} ref={boxTop}>
                        <Semicircle
                            className={semicircleTop}
                            color={semicircleTopColor}
                            transitionDuration={'200ms'}
                        />
                    </div>
                    <div className={code}>
                        <span className={codeText} ref={errorCode}>
                            {t('errorPage.code')}
                        </span>
                    </div>
                    <div className={`${box} ${bottom}`} ref={boxBottom}>
                        <Semicircle
                            orientation={'bottom'}
                            color={semicircleBottomColor}
                            transitionDuration={'200ms'}
                        />
                    </div>
                </div>
                <Markdown className={text}>{t('errorPage.description.one')}</Markdown>
                <div className={buttons}>
                    {portfolioPathname && (
                        <Button as="link" to={portfolioPathname} color="yellow">
                            {t('errorPage.link.portfolio')}
                        </Button>
                    )}
                    {categoryPathname && (
                        <Button as="link" to={categoryPathname} color="pink">
                            {t('errorPage.link.category')}
                        </Button>
                    )}
                    {aboutPathname && (
                        <Button as="link" to={aboutPathname} color="blue">
                            {t('errorPage.link.about')}
                        </Button>
                    )}
                </div>
                <Markdown className={text}>{t('errorPage.description.two')}</Markdown>
            </Section>
        </MainLayout>
    );
};

const query = graphql`
    query {
        allPage(filter: { type: { in: ["portfolio", "about"] } }) {
            edges {
                node {
                    locale
                    pathname
                    type
                }
            }
        }
        allOfferCategory(sort: { fields: sequence, order: ASC }, limit: 1) {
            edges {
                node {
                    ...offerCategoryFields
                }
            }
        }
    }
`;

function getActualTranslateX(element: HTMLDivElement | HTMLSpanElement) {
    const translateX = element.style.transform;
    const openBracket = translateX.indexOf('(');
    const percentSign = translateX.indexOf('%');
    return Number(translateX.slice(openBracket + 1, percentSign));
}

export default ErrorPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
