// extracted by mini-css-extract-plugin
export var bottom = "error-page-module--bottom--cHOQF";
export var box = "error-page-module--box--fPkO1";
export var buttons = "error-page-module--buttons--tDPEl";
export var code = "error-page-module--code--2hAtw";
export var codeText = "error-page-module--code-text--VaSid";
export var container = "error-page-module--container--q+ctn";
export var layout = "error-page-module--layout--c7Sl6";
export var section = "error-page-module--section--8i+0W";
export var semicircleTop = "error-page-module--semicircle-top--IyOsG";
export var text = "error-page-module--text--1pbMK";
export var top = "error-page-module--top--rt+35";